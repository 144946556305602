<template>

  <div class="common-layout">
    <el-container>
      <el-header>
        <a href="https://www.totheshan.vip/resource/totheshan.jpg" target="https://www.totheshan.vip/resource/totheshan.jpg">关于上善之路</a>
      </el-header>

      <el-main>
        <img class="logo" src="https://www.totheshan.vip/resource/totheshan_logo_transparent.png">
        <h1 class="title">请您关注公众号了解详情</h1>
        <img class="wechat_QRecode" src="https://www.totheshan.vip/resource/WeChatOfficialAccount_QRcode.jpg">
      </el-main>

      <el-footer>
        <a href="https://tsm.miit.gov.cn/dxxzsp/">ICP许可证:京B2-20230385</a>
        <a href="https://beian.miit.gov.cn/#/Integrated/index">京ICP备2022031621号-1</a>
<!--        <img src="https://www.totheshan.vip/resource/beian.png">-->
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=11011502037735">京公网安备11011502037735号</a>
        <a>Copyright © 2024 上善之路, All rights reserved.</a>
        <a>北京上善之路文化有限公司</a>

      </el-footer>
    </el-container>
  </div>



</template>

<script>
export default {
  name: 'HomePage',
  props: {
    msg: String

  }
}




</script>

<style scoped>

a {
  text-decoration: none; /* 移除默认的下划线 */
  color:black;
  margin-right: 20px;
}
.el-container {
  height: 100vh;
}
.el-header {
  background-color: #b3c0d1;
  text-align: left;
  line-height: 60px;

  display: flex;
  justify-content:flex-end;
}
.el-main {
  background-color: white;
  text-align: center;
  line-height: 50px;
}
.logo {
  width: 300px;
  margin-top: 80px;

  /*border-color: red;*/
  /*border-width: 1px;*/
  /*border-style: solid;*/
}

.title {
  margin-top: 50px;
  margin-bottom: auto;
  height: 50px;
  font-size: 18px;

  /*border-color: red;*/
  /*border-width: 1px;*/
  /*border-style: solid;*/
}

.wechat_QRecode {
  /*max-width: 100%;*/
  margin-top: 10px;
  margin-bottom: auto;
  max-width: 150px;

  /*border-color: red;*/
  /*border-width: 1px;*/
  /*border-style: solid;*/
}

.el-footer {
  background-color: #b3c0d1;
  /*text-align: center;*/
  line-height: 60px;

  /*display: flex;*/
  /*justify-content: space-between;*/
}

</style>
